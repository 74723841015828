import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/front_end/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/app/(main)/(home)/components/email_confirmation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/app/(main)/(home)/components/engage_block.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/app/(main)/(home)/components/home_search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/app/(main)/(home)/components/questions_carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/front_end/src/app/assets/images/tournament.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/components/onboarding/onboarding_check.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/front_end/src/components/refresh_button.tsx");
